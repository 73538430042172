import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import {
  activateUser,
  fetchUsersListsData,
  inactivateUser,
} from "../../utils/apis";
import moment from "moment";
import formatNumber from "../../helpers/formatNumber";
import { AiFillDelete } from "react-icons/ai";
import {
  deleteUser,
  fetchPlansListsData,
  updateUserPlan,
} from "../../utils/apis";
import { confirmAlert } from "../../utils/sweet-alert-fire";
import { toTitleCase } from "../../utils/helper-methods";
const UsersList = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [searchValue, setSearchValue] = useState("");
  const [usersList, setUsersList] = useState([]);
  const [plans, setPlans] = useState([]);

  const handleStatusChange = async (e) => {
    if (e.target.value === "active") {
      const res = await activateUser(e.target.id, enqueueSnackbar);
      const clonseUsersList = [...usersList];
      const userIndex = clonseUsersList.findIndex(
        (user) => user._id === res._id
      );
      clonseUsersList[userIndex].isActive = res.isActive;
      setUsersList(clonseUsersList);
    } else {
      const res = await inactivateUser(e.target.id, enqueueSnackbar);
      const clonseUsersList = [...usersList];
      const userIndex = clonseUsersList.findIndex(
        (user) => user._id === res._id
      );
      clonseUsersList[userIndex].isActive = res.isActive;
      setUsersList(clonseUsersList);
    }
  };

  const handlePlanChange = async (userId, value) => {
    if (value) {
      const res = await updateUserPlan(
        { userId, planId: value },
        enqueueSnackbar
      );
      console.log("res: ", res);
    }
  };

  const fetchPlansData = async () => {
    const plansData = await fetchPlansListsData(enqueueSnackbar);
    setPlans(plansData);
  };

  const handleDelete = async (id) => {
    const proceed = await confirmAlert();
    if (!proceed) return;

    const response = await deleteUser(id, enqueueSnackbar);

    if (response?.status === 200) {
      fetchData();
      enqueueSnackbar(`User deleted succesfully`, {
        variant: "success",
        autoHideDuration: 3000,
      });
    }
  };

  const fetchData = async () => {
    const filters = {
      search: searchValue,
      perPage: 20,
      page: 1,
      orderBy: "createdAt",
      orderDirection: "asc",
      isActive: "true,false",
      blocked: "true,false",
      type: "USER",
    };
    const users = await fetchUsersListsData(filters, enqueueSnackbar);
    console.log("users: ", users);
    setUsersList(users);
  };

  useEffect(() => {
    fetchData();
    fetchPlansData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  return (
    <div className="users-list-screen">
      <div className="d-flex align-items-center flex-wrap">
        <div className="form-group has-search">
          <img
            src="images/search.png"
            alt=""
            className="form-search-feedback"
          />
          <input
            type="text"
            className="form-control primary-form-control"
            placeholder="Search"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </div>
      </div>

      <div className="mt-5">
        {usersList.length ? (
          <table className="table">
            <thead>
              <tr>
                <th className="user-header-item">Username</th>
                <th className="user-header-item">Company</th>
                <th className="user-header-item header-item-center">
                  Account Status
                </th>
                <th className="user-header-item header-item-center">
                  Usage Req
                </th>
                <th className="user-header-item header-item-center">
                  Plan Expiry
                </th>
                <th className="user-header-item header-item-center">
                  Created on
                </th>
                <th className="user-header-item header-item-center">Status</th>
                <th className="user-header-item header-item-center">Paln</th>
                <th className="user-header-item header-item-center">Action</th>
              </tr>
            </thead>
            <tbody>
              {usersList &&
                usersList.map((user) => {
                  return (
                    <tr className="data-row-verticle-middle" key={user._id}>
                      <td className="user-data-item data-item-captilize">
                        {user.firstName + " " + user.lastName}
                      </td>
                      <td className="user-data-item data-item-captilize data-item-captilize">
                        {user.companyName}
                      </td>
                      <td className="user-data-item data-item-center">
                        {user.isActive ? (
                          <span className="acc-status-badge status-badge-active">
                            {" "}
                            ACTIVATED
                          </span>
                        ) : (
                          <span className="acc-status-badge status-badge-suspended">
                            SUSPENDED
                          </span>
                        )}
                      </td>
                      <td className="user-data-item data-item-center data-item-uppercase">
                        {formatNumber(user.usageReq)}
                      </td>
                      <td className="user-data-item data-item-center data-item-uppercase">
                        Dec 29, 2022
                      </td>
                      <td className="user-data-item data-item-center">
                        {moment(user.createdAt).format(
                          "MMM D, YYYY [at] HH:MM A"
                        )}
                      </td>
                      <td className="bg-transparent">
                        <select
                          id={user._id}
                          className="form-select primary-form-control"
                          onChange={handleStatusChange}
                          defaultValue={user.isActive ? "active" : "suspended"}
                        >
                          <option value="active">Active</option>
                          <option value="suspended">Suspended</option>
                        </select>
                      </td>
                      <td className="bg-transparent">
                        <select
                          id={user._id}
                          className="form-select primary-form-control"
                          onChange={(e) =>
                            handlePlanChange(user._id, e.target.value)
                          }
                          // defaultValue={user.isActive ? "active" : "suspended"}
                        >
                          {plans.map((item) => (
                            <option
                              selected={
                                item?._id === user?.subscribedPlan?.planId
                              }
                              value={item?._id}
                            >
                              {item?.name && toTitleCase(item?.name)}
                            </option>
                          ))}
                        </select>
                      </td>

                      <td className="user-data-item data-item-center ">
                        <button
                          onClick={() => {
                            handleDelete(user._id);
                          }}
                        >
                          <AiFillDelete />
                        </button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        ) : (
          <div>No matching result</div>
        )}
      </div>
    </div>
  );
};

export default UsersList;
