export const ENUM_USER_PERMISSION = {
  DASHBOARD: "dashboard",
  CHAT_SUPPORT: "chat_support",
  USERS_LIST: "users_list",
  PLAN_SEATUP: "plan_setup",
  SERVICES: "services",
  LOCATIONS: "locations",
  BILLING: "billing",
  ADMINS: "admins",
  ANALYTICS: "analytics",
};

export function hasAccess(resource) {
  let user = localStorage.getItem("user") ?? {};
  user = JSON.parse(user);
  return user.permissions.includes(resource) || user.type === "SUPER_ADMIN";
}
