import React, { useRef } from "react";
import { hasAccess } from "../../utils/has-access";
import { ENUM_USER_PERMISSION } from "../../utils/has-access";
const Sidebar = ({ selectedPage, setSelectedPage }) => {
  const ref = useRef(null);
  return (
    <>
      <div className="sidebar-nav-wrapper sidebar-nav-shadow-effect fixed-sidebar-nav">
        {hasAccess(ENUM_USER_PERMISSION.DASHBOARD) && (
          <button
            className={`btn sidebar-nav-btn ${
              selectedPage === "dashboard" ? "sidebar-nav-btn-active" : ""
            }`}
            onClick={() => {
              setSelectedPage("dashboard");
              localStorage.selectedPage = "dashboard";
            }}
          >
            Dashboard
          </button>
        )}
        {hasAccess(ENUM_USER_PERMISSION.USERS_LIST) && (
          <button
            className={`btn sidebar-nav-btn ${
              selectedPage === "usersList" ? "sidebar-nav-btn-active" : ""
            }`}
            onClick={() => {
              setSelectedPage("usersList");
              localStorage.selectedPage = "usersList";
            }}
          >
            Users List
          </button>
        )}
        {hasAccess(ENUM_USER_PERMISSION.ANALYTICS) && (
          <button
            className={`btn sidebar-nav-btn ${
              selectedPage === "analytics" ? "sidebar-nav-btn-active" : ""
            }`}
            onClick={() => {
              setSelectedPage("analytics");
              localStorage.selectedPage = "analytics";
            }}
          >
            Analytics
          </button>
        )}
        {hasAccess(ENUM_USER_PERMISSION.ADMINS) && (
          <button
            className={`btn sidebar-nav-btn ${
              selectedPage === "subAdmins" ? "sidebar-nav-btn-active" : ""
            }`}
            onClick={() => {
              setSelectedPage("subAdmins");
              localStorage.selectedPage = "subAdmins";
            }}
          >
            Sub admins
          </button>
        )}
        {hasAccess(ENUM_USER_PERMISSION.SERVICES) && (
          <button
            className={`btn sidebar-nav-btn ${
              selectedPage === "services" ? "sidebar-nav-btn-active" : ""
            }`}
            onClick={() => {
              setSelectedPage("services");
              localStorage.selectedPage = "services";
            }}
          >
            Services
          </button>
        )}
        {hasAccess(ENUM_USER_PERMISSION.PLAN_SEATUP) && (
          <button
            className={`btn sidebar-nav-btn ${
              selectedPage === "plansSetup" ? "sidebar-nav-btn-active" : ""
            }`}
            onClick={() => {
              setSelectedPage("plansSetup");
              localStorage.selectedPage = "plansSetup";
            }}
          >
            Plans setup
          </button>
        )}
        {hasAccess(ENUM_USER_PERMISSION.CHAT_SUPPORT) && (
          <button
            className={`btn sidebar-nav-btn ${
              selectedPage === "chatSupport" ? "sidebar-nav-btn-active" : ""
            }`}
            onClick={() => {
              setSelectedPage("chatSupport");
              localStorage.selectedPage = "chatSupport";
            }}
          >
            Chat support
          </button>
        )}
        {hasAccess(ENUM_USER_PERMISSION.LOCATIONS) && (
          <button
            className={`btn sidebar-nav-btn ${
              selectedPage === "locations" ? "sidebar-nav-btn-active" : ""
            }`}
            onClick={() => {
              setSelectedPage("locations");
              localStorage.selectedPage = "locations";
            }}
          >
            Locations
          </button>
        )}
        {hasAccess(ENUM_USER_PERMISSION.BILLING) && (
          <button
            className={`btn sidebar-nav-btn ${
              selectedPage === "billsPay" ? "sidebar-nav-btn-active" : ""
            }`}
            onClick={() => {
              setSelectedPage("billsPay");
              localStorage.selectedPage = "billsPay";
            }}
          >
            Bills & Pay
          </button>
        )}
        {
          <button
            className={`btn sidebar-nav-btn ${
              selectedPage === "fribeMap" ? "sidebar-nav-btn-active" : ""
            }`}
            onClick={() => {
              setSelectedPage("fribeMap");
              localStorage.selectedPage = "fribeMap";
            }}
          >
            Fribe Map
          </button>
        }
        {hasAccess(ENUM_USER_PERMISSION.ADMINS) && (
          <button
            className={`btn sidebar-nav-btn ${
              selectedPage === "areaZoneLocation"
                ? "sidebar-nav-btn-active"
                : ""
            }`}
            onClick={() => {
              setSelectedPage("areaZoneLocation");
              localStorage.selectedPage = "areaZoneLocation";
            }}
          >
            Area Zone Location
          </button>
        )}
        {
          <button
            className={`btn sidebar-nav-btn ${
              selectedPage === "countries" ? "sidebar-nav-btn-active" : ""
            }`}
            onClick={() => {
              setSelectedPage("countries");
              localStorage.selectedPage = "countries";
            }}
          >
            Countries
          </button>
        }
        {
          <button
            className={`btn sidebar-nav-btn ${
              selectedPage === "categories" ? "sidebar-nav-btn-active" : ""
            }`}
            onClick={() => {
              setSelectedPage("categories");
              localStorage.selectedPage = "categories";
            }}
          >
            Categories
          </button>
        }
      </div>

      <div
        className="offcanvas offcanvas-start"
        tabIndex="-1"
        id="sideBarOffcanvas"
        aria-labelledby="offcanvasExampleLabel"
      >
        <div className="offcanvas-header">
          <button
            ref={ref}
            type="button"
            className="btn-close ms-auto"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body">
          {hasAccess(ENUM_USER_PERMISSION.DASHBOARD) && (
            <button
              className={`btn sidebar-nav-btn ${
                selectedPage === "dashboard" ? "sidebar-nav-btn-active" : ""
              }`}
              onClick={() => {
                setSelectedPage("dashboard");
                localStorage.selectedPage = "dashboard";
                ref.current.click();
              }}
            >
              Dashboard
            </button>
          )}
          {hasAccess(ENUM_USER_PERMISSION.USERS_LIST) && (
            <button
              className={`btn sidebar-nav-btn ${
                selectedPage === "usersList" ? "sidebar-nav-btn-active" : ""
              }`}
              onClick={() => {
                setSelectedPage("usersList");
                localStorage.selectedPage = "usersList";
                ref.current.click();
              }}
            >
              Users List
            </button>
          )}
          {hasAccess(ENUM_USER_PERMISSION.ANALYTICS) && (
            <button
              className={`btn sidebar-nav-btn ${
                selectedPage === "analytics" ? "sidebar-nav-btn-active" : ""
              }`}
              onClick={() => {
                setSelectedPage("analytics");
                localStorage.selectedPage = "analytics";
                ref.current.click();
              }}
            >
              Analytics
            </button>
          )}
          {hasAccess(ENUM_USER_PERMISSION.ADMINS) && (
            <button
              className={`btn sidebar-nav-btn ${
                selectedPage === "subAdmins" ? "sidebar-nav-btn-active" : ""
              }`}
              onClick={() => {
                setSelectedPage("subAdmins");
                localStorage.selectedPage = "subAdmins";
                ref.current.click();
              }}
            >
              Sub admins
            </button>
          )}
          {hasAccess(ENUM_USER_PERMISSION.PLAN_SEATUP) && (
            <button
              className={`btn sidebar-nav-btn ${
                selectedPage === "plansSetup" ? "sidebar-nav-btn-active" : ""
              }`}
              onClick={() => {
                setSelectedPage("plansSetup");
                localStorage.selectedPage = "plansSetup";
                ref.current.click();
              }}
            >
              Plans setup
            </button>
          )}
          {hasAccess(ENUM_USER_PERMISSION.CHAT_SUPPORT) && (
            <button
              className={`btn sidebar-nav-btn ${
                selectedPage === "chatSupport" ? "sidebar-nav-btn-active" : ""
              }`}
              onClick={() => {
                setSelectedPage("chatSupport");
                localStorage.selectedPage = "chatSupport";
                ref.current.click();
              }}
            >
              Chat support
            </button>
          )}
          {hasAccess(ENUM_USER_PERMISSION.LOCATIONS) && (
            <button
              className={`btn sidebar-nav-btn ${
                selectedPage === "locations" ? "sidebar-nav-btn-active" : ""
              }`}
              onClick={() => {
                setSelectedPage("locations");
                localStorage.selectedPage = "locations";
                ref.current.click();
              }}
            >
              Locations
            </button>
          )}
          {hasAccess(ENUM_USER_PERMISSION.BILLING) && (
            <button
              className={`btn sidebar-nav-btn ${
                selectedPage === "billsPay" ? "sidebar-nav-btn-active" : ""
              }`}
              onClick={() => {
                setSelectedPage("billsPay");
                localStorage.selectedPage = "billsPay";
                ref.current.click();
              }}
            >
              Bills & Pay
            </button>
          )}
          {
            <button
              className={`btn sidebar-nav-btn ${
                selectedPage === "fribeMap" ? "sidebar-nav-btn-active" : ""
              }`}
              onClick={() => {
                setSelectedPage("fribeMap");
                localStorage.selectedPage = "fribeMap";
                ref.current.click();
              }}
            >
              Fribe Map
            </button>
          }
          {hasAccess(ENUM_USER_PERMISSION.ADMINS) && (
            <button
              className={`btn sidebar-nav-btn ${
                selectedPage === "areaZoneLocation"
                  ? "sidebar-nav-btn-active"
                  : ""
              }`}
              onClick={() => {
                setSelectedPage("areaZoneLocation");
                localStorage.selectedPage = "areaZoneLocation";
              }}
            >
              Area Zone Location
            </button>
          )}
          {
            <button
              className={`btn sidebar-nav-btn ${
                selectedPage === "countries" ? "sidebar-nav-btn-active" : ""
              }`}
              onClick={() => {
                setSelectedPage("countries");
                localStorage.selectedPage = "countries";
              }}
            >
              Countries
            </button>
          }
          {
            <button
              className={`btn sidebar-nav-btn ${
                selectedPage === "categories" ? "sidebar-nav-btn-active" : ""
              }`}
              onClick={() => {
                setSelectedPage("categories");
                localStorage.selectedPage = "categories";
              }}
            >
              Categories
            </button>
          }
        </div>
      </div>
    </>
  );
};

export default Sidebar;
