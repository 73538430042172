import React, { useEffect } from "react";
import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";
import { useNavigate } from "react-router-dom";
import { fetchUserData } from "../../utils/apis";
import { useSnackbar } from "notistack";
import UsersList from "../UsersList/UsersList";
import SearchPlace from "../SearchPlace/SearchPlace";
import AddPlace from "../AddPlace/AddPlace";
import Services from "../Services/Services";
import Countries from "../Countries/Countries";
import SubAdmin from "../SubAdmin/SubAdmin";
import PlansSetup from "../PlansSetup/PlansSetup";
import Dashboard from "../Dashboard/Dashboard";
import AreaZoneLocation from "../AreaZoneLocation/AreaZoneLocation";
import Categories from "../Categories/Categories";

const renderSelectedPage = (selectedPage) => {
  switch (selectedPage) {
    case "dashboard":
      return <Dashboard />;
    case "usersList":
      return <UsersList />;
    case "analytics":
      return <div>Analytics</div>;
    case "subAdmins":
      return <SubAdmin />;
    case "plansSetup":
      return <PlansSetup />;
    case "chatSupport":
      return <div>Chat support</div>;
    case "locations":
      return <AddPlace />;
    case "billsPay":
      return <div>Bills & Pay</div>;
    case "fribeMap":
      return <SearchPlace />;
    case "areaZoneLocation":
      return <AreaZoneLocation />;
    case "countries":
      return <Countries />;
    case "categories":
      return <Categories />;
    case "services":
      return <Services />;
    default:
      return null;
  }
};

const Layout = ({ selectedPage, setSelectedPage, userInfo, setUserInfo }) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!localStorage.getItem("access_token")) {
      navigate("/");
    } else {
      const fetchData = async () => {
        const user = await fetchUserData(enqueueSnackbar);
        setUserInfo(user);
      };
      fetchData();
    }
    if (localStorage.getItem("selectedPage")) {
      setSelectedPage(localStorage.getItem("selectedPage"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPage]);

  if (!localStorage.getItem("access_token")) {
    return;
  }

  return (
    <div className="app-layout">
      <Header userInfo={userInfo} />
      <Sidebar selectedPage={selectedPage} setSelectedPage={setSelectedPage} />
      <div className="app-container">{renderSelectedPage(selectedPage)}</div>
    </div>
  );
};

export default Layout;
